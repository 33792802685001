var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "b-container",
        [
          _c(
            "div",
            { staticClass: "margin-left" },
            [
              _c("masthead", {
                attrs: { title: _vm.mastheadTitle },
                on: { changed: _vm.searchChanged },
              }),
              _c(
                "b-button",
                {
                  staticClass: "mb-5",
                  attrs: { variant: "primary" },
                  on: { click: _vm.addRecord },
                },
                [_vm._v("\n      Add new contact\n    ")]
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "contact-list m-0" },
            _vm._l(_vm.people, function (person) {
              return _c(
                "b-card-group",
                { key: person.id, staticClass: "mb-3" },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "pb-0 contact-card",
                      on: {
                        click: function ($event) {
                          return _vm.editRecord(person)
                        },
                      },
                    },
                    [
                      !_vm.isAccountOwner(person)
                        ? _c("fa-icon", {
                            staticClass: "float-right",
                            attrs: { icon: "times" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteRecord(person)
                              },
                            },
                          })
                        : _vm._e(),
                      _c("div", [
                        _c("p", { staticClass: "text-center" }, [
                          _vm.isAccountOwner(person)
                            ? _c("strong", [
                                _vm._v(
                                  "\n              Account Owner\n            "
                                ),
                              ])
                            : _vm.isPhoneContact
                            ? _c("strong", [
                                _vm._v("\n              Contact\n            "),
                              ])
                            : _c("strong", [
                                _vm._v(
                                  "\n              Other Contact\n            "
                                ),
                              ]),
                        ]),
                        _c("hr"),
                      ]),
                      _c("p", { staticClass: "mb-0" }, [
                        !person.contact_is_company
                          ? _c("strong", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.startCase(person.first_name)) +
                                  " " +
                                  _vm._s(_vm.startCase(person.last_name)) +
                                  "\n          "
                              ),
                            ])
                          : _c("strong", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.startCase(person.contact_company_name)
                                  ) +
                                  "\n          "
                              ),
                            ]),
                      ]),
                      _vm.isPhoneContact
                        ? _c(
                            "div",
                            { staticClass: "mt-1" },
                            [
                              _c("hr"),
                              _vm._l(
                                person.person_phones,
                                function (person_phone) {
                                  return _c("div", { key: person_phone.id }, [
                                    _c("p", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.formatPhoneNumber(
                                              person_phone.phone_number
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                      _c(
                                        "strong",
                                        { staticClass: "float-right" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.startCase(person_phone.kind)
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                }
                              ),
                              _c("hr"),
                            ],
                            2
                          )
                        : _vm._e(),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.displayEmail(person)) +
                            "\n        "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c("contact-modal", {
            ref: "contact-modal",
            attrs: {
              title: _vm.title,
              type: _vm.modalType,
              "is-phone-contact": _vm.isPhoneContact,
            },
          }),
        ],
        1
      )
    : _c("ct-centered-spinner", { attrs: { height: "260px" } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }