<template>
  <b-container v-if="loaded">
    <div class="margin-left">
      <masthead :title="mastheadTitle" @changed="searchChanged" />
      <b-button class="mb-5" variant="primary" @click="addRecord">
        Add new contact
      </b-button>
    </div>
    <b-container class="contact-list m-0">
      <b-card-group v-for="person in people" :key="person.id" class="mb-3">
        <b-card class="pb-0 contact-card" @click="editRecord(person)">
          <fa-icon v-if="!isAccountOwner(person)" icon="times" class="float-right" @click="deleteRecord(person)" />
          <div>
            <p class="text-center">
              <strong v-if="isAccountOwner(person)">
                Account Owner
              </strong>
              <strong v-else-if="isPhoneContact">
                Contact
              </strong>
              <strong v-else>
                Other Contact
              </strong>
            </p>
            <hr>
          </div>
          <p class="mb-0">
            <strong v-if="!person.contact_is_company">
              {{ startCase(person.first_name) }} {{ startCase(person.last_name) }}
            </strong>
            <strong v-else>
              {{ startCase(person.contact_company_name) }}
            </strong>
          </p>
          <div v-if="isPhoneContact" class="mt-1">
            <hr/>
            <div v-for="person_phone in person.person_phones" :key="person_phone.id">
              <p>
                {{ formatPhoneNumber(person_phone.phone_number) }}
                <strong class="float-right">
                  {{ startCase(person_phone.kind) }}
                </strong>
              </p>
            </div>
            <hr/>
          </div>
          <p class="mb-0">
            {{ displayEmail(person) }}
          </p>
        </b-card>
      </b-card-group>
    </b-container>
    <contact-modal ref="contact-modal" :title="title" :type="modalType" :is-phone-contact="isPhoneContact" />
  </b-container>
  <ct-centered-spinner v-else height="260px" />
</template>

<script>
import _ from 'lodash'
import Masthead from '@/components/shared/Masthead'
import ContactModal from '@/components/ContactModal'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'
import { mapGetters, mapActions } from 'vuex'
import { formatPhoneToCountryCodeStyle, formatPhoneWithCountry } from '@/common/modules/formatters'

export default {
  name: 'Contacts',
  components: { Masthead, ContactModal, CtCenteredSpinner },
  props: {
    mastheadTitle: String,
    phoneSupportContact: {
      type: Object,
      default: null,
    },
    isPhoneContact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: null,
      deleteClicked: false,
      title: '',
      modalType: '',
      searchString: '',
    }
  },
  computed: {
    ...mapGetters('account', ['filteredPeople', 'account']),
    people() {
      if (this.phoneSupportContact) {
        return this.filteredPeople(this.searchString).concat(this.phoneSupportContact)
      }

      return this.filteredPeople(this.searchString)
    },
  },
  async mounted() {
    this.loaded = false
    await this.loadAccount()
    this.loaded = true
  },
  methods: {
    ...mapActions('account', ['loadAccount', 'deleteContact']),
    ...mapActions('sms', ['findChatRoomByNumber']),
    formatPhoneNumber(number) {
      return formatPhoneWithCountry(formatPhoneToCountryCodeStyle(number))
    },
    async searchChanged(searchObject) {
      this.searchString = searchObject.query
    },
    displayEmail(person) {
      const email = person.person_emails.find(email => email.kind === 'primary')

      return email ? email.email_address : ''
    },
    addRecord() {
      this.title = 'Add a contact'
      this.modalType = 'add'
      this.$refs['contact-modal'].show()
    },
    async deleteRecord(person) {
      this.deleteClicked = true
      try {
        const res = await this.$bvModal.msgBoxConfirm(
          'Are you sure you want to remove this contact?', {
            title: 'Remove Contact',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'primary',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          })
        if (res) {
          await this.deleteContact({ person_id: person.id })
          this.$bvToast.toast('Contact deleted successfully!', {
            title: 'Success',
            variant: 'success',
          })
        }
      } catch (error) {
        const errorMessage = error.response ? error.response.data.error.message : 'An error occurred when trying to delete the contact.'

        this.$bvToast.toast(errorMessage, {
          title: 'Error',
          variant: 'danger',
        })
      }
      this.deleteClicked = false
    },
    editRecord(person) {
      if (!this.deleteClicked) {
        this.title = 'Edit contact'
        this.modalType = 'edit'
        this.$refs['contact-modal'].show(person)
      }
    },
    startCase: input => _.startCase(input),
    isAccountOwner(person) {
      return person.id === this.account.id
    },
  },
}
</script>

<style lang="scss" scoped>
.margin-left {
  margin-left: 1.5em;
}

.contact-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .contact-card {
    @include ct-ui-card-shadow;
    @include ct-ui-selectable;

    min-height: 100px;
    flex-grow: 1;
    flex-shrink: 0;
    min-width: 330px;
    max-width: 500px;
    margin: 10px;
    border-radius: 15px;
  }
}

@media only screen and (max-width: 660px) {
  .margin-left {
    margin-left: 0;
  }

  .container {
    margin: 0 !important;
    padding: 0 !important;
  }

  .contact-list {
    display: flex;
    flex-flow: column nowrap;

    .contact-card {
      flex-grow: 0;
      min-width: 100%;
      margin: 0;
    }
  }
}
</style>
